exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leeds-basement-js": () => import("./../../../src/pages/leeds/basement.js" /* webpackChunkName: "component---src-pages-leeds-basement-js" */),
  "component---src-pages-leeds-index-js": () => import("./../../../src/pages/leeds/index.js" /* webpackChunkName: "component---src-pages-leeds-index-js" */),
  "component---src-pages-leeds-level-1-js": () => import("./../../../src/pages/leeds/level-1.js" /* webpackChunkName: "component---src-pages-leeds-level-1-js" */),
  "component---src-pages-leeds-level-2-js": () => import("./../../../src/pages/leeds/level-2.js" /* webpackChunkName: "component---src-pages-leeds-level-2-js" */),
  "component---src-pages-leeds-level-3-js": () => import("./../../../src/pages/leeds/level-3.js" /* webpackChunkName: "component---src-pages-leeds-level-3-js" */),
  "component---src-pages-leeds-level-4-js": () => import("./../../../src/pages/leeds/level-4.js" /* webpackChunkName: "component---src-pages-leeds-level-4-js" */)
}

